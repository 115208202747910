import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCkXcHkzmR9ahFZpuM_L1cqcaUrwGMXy4o",
  authDomain: "prime-finance-markets.firebaseapp.com",
  projectId: "prime-finance-markets",
  storageBucket: "prime-finance-markets.appspot.com",
  messagingSenderId: "862170593135",
  appId: "1:862170593135:web:f7f0501059adbecfbb9a9a",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
